<template>
  <div>
    <main-contenedor
      title="Sobre nosotros"
      subtitle="Somos una empresa fundada en 2012 con el fin de proveer Soluciones integrando Tecnología vinculada a los Sistemas de Información, Infraestructura Informática, Automatización y Control Industrial. Disponemos de personal altamente profesional y capacitado que garantiza a nuestros clientes la cobertura de sus necesidades."
    ></main-contenedor>
    <somos-container></somos-container>
  </div>
</template>

<script>
import mainContenedor from "@/components/mainContenedor.vue";
import somosContainer from "@/components/somosContainer.vue";
export default {
  name: "aboutView",
  components: {
    mainContenedor,
    somosContainer,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/variables/colors.scss";

.container {
  max-width: 100%;
}
</style>
