<template>
  <div>
    <div v-for="person in data" :key="person.id">
      <b-card
        data-aos="flip-left"
        data-aos-duration="1000"
        data-aos-offset="20"
      >
        <b-row>
          <b-col md="6" lg="6">
            <b-card-img
              v-if="person.imag"
              :src="requireFiles(person.imag)"
              style="max-width: 25rem"
            />
          </b-col>
          <b-col md="6" lg="6">
            <b-card-body>
              <b-card-title>{{ person.name }}</b-card-title>
              <b-card-sub-title>{{ person.title }}</b-card-sub-title>
              <b-card-text> {{ person.bio }} </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import people from "@/assets/json/somos.json";
import files from "@/mixins/files";
export default {
  props: [people],
  mixins: [files],
  data() {
    return {
      data: people,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
h4 {
  font-size: 2.2rem;
}
h6 {
  font-size: 2rem;
}
p {
  font-size: 1.8rem;
}
.card {
  margin: auto;
}
.row {
  margin: auto;
  text-align: center;
  align-content: center;
}
.row img {
  border-radius: 50%;
}
@media (min-width: 768px) {
  .row {
    text-align: center;
  }
  .col {
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .col-md-6,
  .col-lg-6 {
    display: flex;
    text-align: center;
    align-items: center;
  }
}
</style>
