import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import gsap from "gsap";
import { TextPlugin } from "gsap/TextPlugin";
import "./scss/index.scss";
import ScrollAnimation from "./directives/scrollanimation";
import { BootstrapVue, IconsPlugin, BIcon, CardPlugin } from "bootstrap-vue";
import { CarouselPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { LottiePlayer } from "@lottiefiles/lottie-player";
gsap.registerPlugin(TextPlugin);
Vue.use(
  BootstrapVue,
  CarouselPlugin,
  IconsPlugin,
  BIcon,
  CardPlugin,
  TextPlugin,
  LottiePlayer
);

Vue.directive("scrollanimation", ScrollAnimation);
Vue.config.productionTip = false;

Vue.mixin({
  created: function () {
    this.gsap = gsap;
  },
});

new Vue({
  router,
  render: (h) => h(App),
  mounted() {
    AOS.init();
  },
}).$mount("#app");
