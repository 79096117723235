<template>
  <div class="card-columns">
    <div v-for="client in data" :key="client.id">
      <!-- <b-card
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="30"
        img-top
      > -->
      <b-card img-top>
        <div class="imglogo">
          <b-card-img
            v-if="client.logo"
            :src="requireFiles(client.logo)"
            style="max-width: 25rem"
            img-top
          />
        </div>
        <b-card-body>
          <b-card-text> {{ client.body }} </b-card-text>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import clients from "@/assets/json/clientes.json";
import files from "@/mixins/files";
export default {
  props: [clients],
  mixins: [files],
  data() {
    return {
      data: clients,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
h4 {
  font-size: 2.2rem;
}
h6 {
  font-size: 2rem;
}
p {
  font-size: 1.8rem;
}
.card {
  margin: auto;
  text-align: justify;
  border: none;
}
div.imglogo {
  text-align: center;
  margin-top: 2rem;
}
img {
  width: 50%;
}
</style>
