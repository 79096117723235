<template>
  <footer>
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="4">
          <div class="comp">
            <h3>RCL SISTEMAS INDUSTRIALES S.R.L</h3>
            <b-nav-item to="/about">Sobre Nosotros</b-nav-item>
            <b-nav-item to="/services">Nuestros Servicios</b-nav-item>
            <b-nav-item to="/clientes">Nuestros Clientes</b-nav-item>
            <!-- <b-nav-item to="/clientes">Contacto</b-nav-item> -->
          </div>
        </b-col>
        <b-col cols="12" lg="4">
          <img src="../assets/img/logo.png" />
        </b-col>
        <b-col cols="12" lg="4">
          <div class="info">
            <p>
              Esteban Echeverría 3750 – Piso 2do. R4 B1605DTJ | Vicente López |
              Norcenter Lifestyle Mall| Buenos Aires, Argentina<br />
              Tel: +54 911 5689-5927 cel: +54 911 5412-7700<br />
              info@rcl-sistemas.net
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import files from "@/mixins/files";
export default {
  mixins: [files],
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables/colors.scss";
@import "@/scss/variables/texts.scss";
footer {
  background-color: $negro;
  height: fit-content;
  text-align: center;
  font-family: $fuenteParrafos;
  padding-top: 0.7rem;
}
div .comp {
  margin: auto;
  text-align: center;
}
li.nav-item {
  list-style: none;
  margin: auto;
}
a.nav-link,
h3 {
  color: $celeste;
  font-size: 1.8rem;
  padding: 0.2rem;
}
div.col-lg-4,
.col-12 {
  text-align: center;
  align-self: center;
}
div .info p {
  font-size: 2rem;
  color: $celeste;
  text-decoration: none;
  text-align: center;
}
img {
  max-width: 10%;
  opacity: 0.2;
  margin: auto;
}
@media (min-width: 920px) {
  img {
    padding-top: 0.6rem;
    max-width: 40%;
  }
}
</style>
