import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ClientesView from "../views/ClientesView.vue";
import ServicesView from "../views/ServicesView.vue";
// import ContactoView from "../views/ContactoView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/clientes",
    name: "clientes",
    component: ClientesView,
  },
  {
    path: "/services",
    name: "services",
    component: ServicesView,
  },
  // {
  //   path: "/pruebaRCL/contacto",
  //   name: "contacto",
  //   component: ContactoView,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
