<template>
  <b-navbar toggleable>
    <b-navbar-brand href="/">RCL</b-navbar-brand>
    <b-navbar-toggle target="navbar-toggle-collapse">
      <template #default="{ expanded }">
        <b-icon v-if="expanded"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-menu-2"
            width="52"
            height="52"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="#e0e0f6"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="4" y1="6" x2="20" y2="6" />
            <line x1="4" y1="12" x2="20" y2="12" />
            <line x1="4" y1="18" x2="20" y2="18" /></svg
        ></b-icon>
        <b-icon v-else
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-menu-2"
            width="52"
            height="52"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="#e0e0f6"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="4" y1="6" x2="20" y2="6" />
            <line x1="4" y1="12" x2="20" y2="12" />
            <line x1="4" y1="18" x2="20" y2="18" /></svg
        ></b-icon>
      </template>
    </b-navbar-toggle>
    <b-collapse id="navbar-toggle-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/">Home</b-nav-item>
        <b-nav-item to="/about">Quiénes somos</b-nav-item>
        <b-nav-item to="/services">Nuestros servicios</b-nav-item>
        <b-nav-item to="/clientes">Clientes</b-nav-item>
        <!-- <b-nav-item to="/contacto">Contacto</b-nav-item> -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
@import "@/scss/variables/texts.scss";
.navbar {
  background-color: $negro;
}
div .navbar-brand {
  color: $celeste;
}
.navbar-brand {
  font-size: 3rem;
  margin-left: 2rem;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
button {
  width: 5rem;
}
.navbar-light .navbar-toggler {
  margin-right: 1.2rem;
  border: none;
}
.navbar-light .navbar-nav .nav-link {
  color: $celeste;
  font-size: 1.8rem;
  margin-left: 2rem;
}
button.navbar-toggler .navbar-toggler .not-collapsed {
  border: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
</style>
