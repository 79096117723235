<template>
  <div class="cards" style="padding: 0">
    <b-card
      bg-variant="light"
      class="text-center"
      data-aos="fade-zoom-in"
      data-aos-duration="1000"
      data-aos-offset="-30"
    >
      <div class="iconos">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-adjustments"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="#e0e0f6"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="6" cy="10" r="2" />
          <line x1="6" y1="4" x2="6" y2="8" />
          <line x1="6" y1="12" x2="6" y2="20" />
          <circle cx="12" cy="16" r="2" />
          <line x1="12" y1="4" x2="12" y2="14" />
          <line x1="12" y1="18" x2="12" y2="20" />
          <circle cx="18" cy="7" r="2" />
          <line x1="18" y1="4" x2="18" y2="5" />
          <line x1="18" y1="9" x2="18" y2="20" />
        </svg>
      </div>
      <b-card-text>
        <h3>Automatización y puesta en marcha</h3>
        <p>
          Ofrecemos sistemas y soluciones en el área de tele-supervisión,
          medición y control integrando diferentes herramientas y productos,
          desarrollando aplicaciones, interfaces y protocolos de comunicación,
          que incrementan el valor de los sistemas al permitir acceso a la
          información, alta confiabilidad y un muy buen desempeño.
        </p>
      </b-card-text>
    </b-card>
    <b-card
      bg-variant="light"
      class="text-center"
      data-aos="fade-zoom-in"
      data-aos-duration="1000"
      data-aos-offset="-30"
    >
      <div class="iconos">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chart-dots"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#e0e0f6"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 3v18h18" />
          <circle cx="9" cy="9" r="2" />
          <circle cx="19" cy="7" r="2" />
          <circle cx="14" cy="15" r="2" />
          <line x1="10.16" y1="10.62" x2="12.5" y2="13.5" />
          <path d="M15.088 13.328l2.837 -4.586" />
        </svg>
      </div>
      <b-card-text>
        <h3>Procesamiento de datos – Big Data</h3>
        <p>
          Mediante software específico sintetizamos datos de toda la planta,
          revelando relaciones que de otro modo serían imperceptibles. Modelamos
          Big Data en fragmentos manejables, para que los equipos industriales
          puedan realizar un seguimiento del rendimiento frente a las métricas
          más importantes, con el tiempo suficiente para marcar la diferencia.
        </p>
      </b-card-text>
    </b-card>
    <b-card
      bg-variant="light"
      class="text-center"
      data-aos="fade-zoom-in"
      data-aos-duration="1000"
      data-aos-offset="-30"
    >
      <div class="iconos">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-school"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#e0e0f6"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
          <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
        </svg>
      </div>
      <b-card-text>
        <h3>Capacitaciones</h3>
        <p>
          El entrenamiento es muy importante y nos permite asegurar el correcto
          mantenimiento de los equipos y sistemas. Sostener el elevado nivel
          técnico de nuestros clientes garantiza el éxito de las soluciones
          implementadas. Es por eso que ofrecemos cursos y programas de
          capacitación cuyos contenidos son adaptados a las necesidades
          particulares o proyectos específicos.
        </p>
      </b-card-text>
    </b-card>
    <b-card
      bg-variant="light"
      class="text-center"
      data-aos="fade-zoom-in"
      data-aos-duration="1000"
      data-aos-offset="-30"
    >
      <div class="iconos">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-tool"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#e0e0f6"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5"
          />
        </svg>
      </div>
      <b-card-text>
        <h3>Soporte y Mantenimiento</h3>
        <p>
          El soporte post-venta y el mantenimiento son herramientas que permite
          asegurar las inversiones en automatización. Un soporte efectivo o un
          contrato de mantenimiento permite a nuestros clientes separarse de las
          complejas tareas que exigen los Sistemas de Información actuales.
        </p>
      </b-card-text>
    </b-card>
    <b-card
      bg-variant="light"
      class="text-center"
      data-aos="fade-zoom-in"
      data-aos-duration="1000"
      data-aos-offset="-30"
    >
      <div class="iconos">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-file-certificate"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#e0e0f6"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M14 3v4a1 1 0 0 0 1 1h4" />
          <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5" />
          <circle cx="6" cy="14" r="3" />
          <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5" />
        </svg>
      </div>
      <b-card-text>
        <h3>Licencias de software</h3>
        <p>
          Como integradores certificados de software aplicado a la industria,
          proveemos el asesoramiento indispensable para la provisión de
          licencias de las marcas Aveva Wonderware para sus productos Wonderware
          System Platform y Kepware para su producto KepServerEx.
        </p>
      </b-card-text>
    </b-card>
    <b-card
      bg-variant="light"
      class="text-center"
      data-aos="fade-zoom-in"
      data-aos-duration="1000"
      data-aos-offset="-30"
    >
      <div class="iconos">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-affiliate"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#e0e0f6"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275" />
          <path d="M11.683 12.317l5.759 -5.759" />
          <circle cx="5.5" cy="5.5" r="1.5" />
          <circle cx="18.5" cy="5.5" r="1.5" />
          <circle cx="18.5" cy="18.5" r="1.5" />
          <circle cx="8.5" cy="15.5" r="4.5" />
        </svg>
      </div>
      <b-card-text>
        <h3>Integración de productos de Aveva</h3>
        <p>
          Desarrollamos, Instalamos y ponemos en producción sistemas basados en
          System Platform y productos relacionado gracias a nuestros
          profesionales certificados en dicha plataforma.
        </p>
      </b-card-text>
    </b-card>
  </div>
</template>
<script>
export default {};
</script>

<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: none;
  width: 100%;
}
.card {
  border-radius: 2rem;
  margin: 1rem;
  padding: 1rem;
  width: 100%;
}
.iconos {
  display: flex;
  height: 150px;
  width: 150px;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  background-color: $negro;
  border-radius: 50%;
}
h3 {
  font-size: 3rem;
}
@media (min-width: 920px) {
  .cards {
    justify-content: space-between;
  }
  .card {
    max-width: 30%;
  }
}
</style>
