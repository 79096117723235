<template>
  <div>
    <div v-if="item.anim">
      <b-row class="seccion">
        <b-col
          cols="12"
          lg="6"
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-offset="30"
        >
          <h1>{{ item.title }}</h1>
          <p>{{ item.body }}</p>
        </b-col>
        <b-col cols="12" lg="6" class="containerimg">
          <lottie-component :src="item.anim" />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row class="seccionmedio">
        <b-col cols="12" lg="12">
          <p
            data-aos="fade-zoom-in"
            data-aos-duration="2500"
            data-aos-offset="30"
          >
            {{ item.body }}
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import files from "@/mixins/files";
import lottieComponent from "@/components/lottieComponent.vue";
export default {
  mixins: [files],
  components: { lottieComponent },
  props: {
    item: {
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
.row {
  margin: auto;
  padding-top: 2rem;
}

div .seccion h1 {
  font-size: 5rem;
  font-weight: 300;
  padding-bottom: 1rem;
  text-align: left;
}

div .seccion p {
  font-size: 2.5rem;
  text-align: justify;
}
div .seccionmedio p {
  text-align: justify;
  font-style: italic;
  color: $negro;
  font-size: 2.5rem;
}

div .seccion img {
  max-width: 100%;
}

.col-12 {
  text-align: center;
}
@media (min-width: 768px) {
  .col-12 {
    text-align: right;
  }
  div .seccion img {
    max-width: 100%;
  }
  .containerimg {
    text-align: center;
  }
  .containerimg img {
    width: 50%;
  }
  div .seccionmedio p {
    font-weight: 500;
    color: $negro;
    font-size: 3.5rem;
    font-style: italic;
  }
}
</style>
