<template>
  <div id="containerlogo" v-on:click="onClick" class="container logo">
    <div>
      <img class="imglogo" ref="logo" src="../assets/img/logo.png" />
      <!-- <h2 class="namecompany" ref="company">RCL SISTEMAS INDUSTRIALES</h2> -->
      <img class="namecompany" ref="company" src="../assets/img/company.png" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.gsap.from(this.$refs.logo, {
      duration: 1,
      x: -700,
      rotation: -360,
      ease: "power1.out",
      delay: 1,
    });
    this.gsap.from(this.$refs.company, {
      delay: 1.5,
      duration: 1.5,
      opacity: 0,
      ease: "none",
    });
  },
  methods: {
    onClick: function () {
      var tl = this.gsap.timeline();
      tl.to(this.$refs.logo, {
        duration: 0.5,
        y: -350,
        ease: "power4.out",
      });
      tl.to(this.$refs.logo, {
        duration: 1,
        ease: "Bounce.easeOut",
        y: 0,
        yoyo: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
@import "@/scss/variables/texts.scss";
.imglogo {
  width: 63%;
  margin-top: 6rem;
  cursor: pointer;
}
.namecompany {
  max-width: 63%;
}
@media (min-width: 600px) {
  .imglogo {
    width: 58%;
    height: auto;
  }
  .namecompany {
    max-width: 58%;
  }
}
@media (min-width: 768px) {
  .imglogo {
    width: 90%;
  }
  .namecompany {
    max-width: 90%;
  }
}
</style>
