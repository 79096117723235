<template>
  <div>
    <main-container></main-container>
    <info-container></info-container>
  </div>
</template>

<script>
import MainContainer from "@/components/mainContainer.vue";
import InfoContainer from "@/components/infoContainer.vue";
export default {
  name: "homeView",
  components: {
    MainContainer,
    // ValoresContainer,
    InfoContainer,
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
@import "@/scss/index.scss";
</style>
