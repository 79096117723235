<template>
  <lottie-player
    :src="lottie"
    background="transparent"
    :speed="speed"
    style="width: 100%; height: 300px"
    :autoplay="autoplay"
    :loop="loop"
  />
</template>

<script>
import files from "@/mixins/files";
export default {
  mixins: [files],
  props: {
    src: {
      type: [String],
      default: "",
      required: true,
    },
    speed: {
      type: [String],
      default: "0.6",
    },
    autoplay: {
      type: [Boolean],
      default: true,
    },
    loop: {
      type: [Boolean],
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    lottie() {
      return JSON.stringify(this.requireFiles(this.src));
    },
  },
};
</script>
