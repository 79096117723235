<template>
  <b-container fluid>
    <b-row>
      <main-contenedor
        title="Nuestros clientes"
        subtitle="Estamos orgullosos de proveer los mejores servicios para las principales empresas de la industria, que desde hace muchos años siguen confiando en nosotros."
      >
      </main-contenedor>
    </b-row>
    <client-container></client-container>
  </b-container>
</template>

<script>
import MainContenedor from "@/components/mainContenedor.vue";
import clientContainer from "@/components/clientContainer.vue";
export default {
  name: "clientesView",
  components: {
    MainContenedor,
    clientContainer,
  },
};
</script>
<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
</style>
