<template>
  <b-container fluid>
    <section-container v-for="item in items" :key="item.id" :item="item" />
  </b-container>
</template>

<script>
import files from "@/mixins/files";
import sectionContainer from "./sectionContainer.vue";
export default {
  components: { sectionContainer },
  mixins: [files],
  data() {
    return {
      items: this.requireFiles("info.json"),
    };
  },
  computed: {},
};
</script>
