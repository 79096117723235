<template>
  <div id="app">
    <navbar></navbar>
    <div id="router-view">
      <router-view />
    </div>
    <body></body>
    <div>
      <footter-menu></footter-menu>
    </div>
  </div>
</template>
<script>
import navbar from "./components/navBar.vue";
import footterMenu from "./components/footterMenu.vue";
export default {
  components: {
    navbar,
    footterMenu,
  },
};
</script>
<style lang="scss">
@import "@/scss/variables/colors.scss";
@import "@/scss/variables/texts.scss";
@import "@/scss/index.scss";
</style>
