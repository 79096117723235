<template>
  <b-container fluid>
    <h1 ref="heading">Ingeniería de Control</h1>
    <h2>Sistemas SCADA</h2>
    <div class="menu">
      <h3>Integración de Sistemas</h3>
      <h3>Automatización</h3>
      <h3>Soporte y Mantenimiento</h3>
      <h3>Capacitación</h3>
      <h3>Big Data</h3>
      <h3>Licencias de software</h3>
    </div>
  </b-container>
</template>

<script>
export default {
  // mounted() {
  //   this.gsap.from(this.$refs.heading, {
  //     duration: 2,
  //     rotation: -360,
  //     ease: "power1.out",
  //     delay: 1,
  //   });
  //   this.gsap.heading.splitColor("red");
  // },
};
</script>

<style scoped lang="scss">
@import "@/scss/variables/colors.scss";
@import "@/scss/variables/texts.scss";

div .container {
  margin-top: 0.5rem;
}
h1,
h2,
h3 {
  text-align: center;
}
h1 {
  font-size: 3.6rem;
  font-weight: bold;
}
h2 {
  font-size: 3rem;
  font-weight: bold;
}

h3 {
  text-decoration: none;
  font-size: 2.6rem;
}
@media (min-width: 600px) {
  h1 {
    font-size: 6rem;
  }
  h2 {
    font-size: 4.5rem;
  }

  .menu {
    padding-top: 1rem;
  }

  h3 {
    display: block;
    font-size: 3.5rem;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 8rem;
  }
  h2 {
    font-size: 4.5rem;
  }
  h3 {
    display: block;
    font-size: 3.8rem;
    padding: 0.4rem;
  }
}
</style>
