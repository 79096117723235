<template>
  <div>
    <main-contenedor
      title="Nuestros Servicios"
      subtitle="Formulamos propuestas de valor combinando productos y servicios para crear Soluciones. Obtuvimos premios y menciones de performance para productos de Wonderware, aunque la mejor certificación es, sin duda, la satisfacción de haber cumplido con los objetivos trazados por nuestros clientes."
    >
    </main-contenedor>
    <div class="cards">
      <services-container></services-container>
    </div>
  </div>
</template>

<script>
import ServicesContainer from "@/components/servicesContainer.vue";
import mainContenedor from "@/components/mainContenedor.vue";
export default {
  name: "servicesView",
  components: { mainContenedor, ServicesContainer },
};
</script>
<style>
.cards {
  padding: 3rem;
}
</style>
